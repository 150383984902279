<template>
    <div class="card">
        <ul class="list-group list-group-flush">
            <li class="list-group-item">
                <h5 class="card-title">View user</h5>
            </li>
            <li class="list-group-item">
                <h5 class="card-title">Email</h5>
                {{ user.email }}
            </li>
            <li class="list-group-item">
                <h5 class="card-title">First name</h5>
                {{ user.profile?.first_name }}
            </li>
            <li class="list-group-item">
                <h5 class="card-title">Last name</h5>
                {{ user.profile?.last_name }}
            </li>
            <li class="list-group-item">
                <h5 class="card-title">Phone</h5>
                {{ user.profile?.phone_number }}
            </li>
            <li class="list-group-item">
                <h5 class="card-title">Language</h5>
                {{ user.profile?.language_name }}
            </li>
            <li class="list-group-item">
                <h5 class="card-title">Country</h5>
                {{ user.profile?.country_name }}
            </li>
            <li class="list-group-item">
                <h5 class="card-title">Created at</h5>
                {{ user.created_at }}
            </li>
            <li class="list-group-item">
                <h5 class="card-title">Updated at</h5>
                {{ user.updated_at }}
            </li>
            <li class="list-group-item">
                <h5 class="card-title">Group name</h5>
                {{ user.user_group_name }}
            </li>
            <li class="list-group-item">
                <h5 class="card-title">Active</h5>
                {{ user.active ? 'Active' : 'Not active' }}
            </li>
            <li class="list-group-item">
                <h5 class="card-title">Verified</h5>
                {{ user.verified ? 'Verified' : 'Not verified' }}
            </li>
            <li class="list-group-item">
                <h5 class="card-title">Last login</h5>
                {{ user.last_login || 'N/A' }}
            </li>
        </ul>

        <div class="card-body">
            <router-link :to="{ name: 'EditUser', params: { id: user.id } }" class="card-link">Edit User</router-link>
            <a @click.prevent="deleteUser" href="#" class="card-link">Delete link</a>
        </div>

    </div>
</template>

<script>
import {userService} from '@/services/settings/userService'

export default {
    name: "ViewUser",
    data() {
        return {
            user: {},
        }
    },
    computed: {
        userId() {
            return this.$route.params.id
        }
    },
    created() {
        this.getUser()
    },
    methods: {
        getUser() {
            userService.showUser(this.userId).then(response => {
                this.user = response.data.data
            })
        },
        deleteUser() {
            let areYouSure = confirm('Are you sure?')

            if (areYouSure) {
                userService.delete(this.user.id).then(response => {
                    this.user.active = false
                })
            }
        }
    }
}
</script>

<style scoped>
li {
    margin-bottom: 0;
}
</style>